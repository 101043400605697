.logo {
  height: 9vh;
}

.nav-theme {
  height: 10vh;
  background-color: #101018f8;
  font-size: 20px;
}

.nav-link-item {
  margin-left: 1vw;
  margin-right: 1vw;
}

.animate-navbar {
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@media screen and (max-width: 640px) {
  .logo {
    height: 9vh;
    position: fixed;
    top: 0;
  }

  .navbar-toggler {
    position: fixed;
    right: 15px;
    top: 15px;
  }

  .navbar-collapse {
    margin-top: 9vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    background-color: rgba(16, 16, 24, 0.973);
    padding: 3vw;
    width: 40vw;
  }
}
