.accordian-main {
  cursor: pointer;
  display: inline-block;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 14px;
  background-color: rebeccapurple;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
}

.markdown {
  color: rebeccapurple;
  font-weight: 700;
  font-size: 1.3rem;
}

.list-styles {
  list-style-type: circle;
}

.image-style1 {
  width: 3em;
  height: 3em;
}

@media screen and (max-width: 680px) {
  /* * {
    padding: 0;
    margin: 0;
  } */

  /* .container-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
  } */
}
