hr {
  border: 0;
  height: 1.5px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}
.hr-skills,
.hr-projects,
.hr-contact,
.hr-footer {
  border: 0;
  height: 1.5px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
}

.container-box {
  width: 80%;
  background-color: transparent;
  position: relative;
  z-index: 9;
}

.particles-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@media screen and (max-width: 680px) {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .container-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96%;
    padding: 0;
    margin: 0;
  }
  .img-about {
    margin-left: 0;
  }
}
